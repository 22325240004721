@tailwind base;
@tailwind components;
@tailwind utilities;

.grn-clr {
  color: #007f73;
}
.ylw-clr {
  color: #ffc700;
}
.txt-clr {
  color: #33363b;
}
